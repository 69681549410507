import { Controller } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import DatePicker from "@/components/DatePicker";
import FormErrorMessage from "@/components/FormErrorMessage";
import {
  ADDON_INVENTORY_DURATION_OPTIONS,
  COLLEGECREDIT_INVENTORY_DURATION_OPTIONS,
  FORM_PAPER_HEADING_SX,
  FORM_PAPER_SX,
  PROGRAM_INVENTORY_DURATION_OPTIONS,
} from "@/constants";

const ProductDates = ({ control, errors, type }) => {
  const getCohortLengths = (type) => {
    switch (type) {
      case "addOn":
        return ADDON_INVENTORY_DURATION_OPTIONS;
      case "program":
        return PROGRAM_INVENTORY_DURATION_OPTIONS;
      case "collegeCredit":
        return COLLEGECREDIT_INVENTORY_DURATION_OPTIONS;
      default:
        return [];
    }
  };

  return (
    <>
      <Typography variant="h6" component="h3" sx={FORM_PAPER_HEADING_SX}>
        Product Dates
      </Typography>
      <Paper
        elevation={1}
        sx={FORM_PAPER_SX}
        className="animation-fade-in-slow"
      >
        <Stack spacing={2} alignItems="flex-start">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              control={control}
              required
              label="Start Date*"
              name="startDate"
            />
            <FormErrorMessage error={errors["startDate"]} />
          </LocalizationProvider>
          <FormControl sx={{ minWidth: "175px" }}>
            <InputLabel id="item-select-cohort-length">
              Cohort Length*
            </InputLabel>
            <Controller
              control={control}
              name="cohortLength"
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="item-select-cohort-length"
                  size="small"
                  label="Cohort Length*"
                >
                  {getCohortLengths(type).map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              rules={{ required: "This field is required" }}
            />
          </FormControl>
        </Stack>
      </Paper>
    </>
  );
};
export default ProductDates;
